<template>
    <div class="row">
        <div class="col-xl-10 m-auto">
            <div class="card" >
                <h2 class="mt-4 ml-4 card-header-title">{{$t('streamings.streamer')}}</h2>
                <div class="card-body" v-if="load && streamings.length">
                  <div v-for="data of streamings" :key="data.id">
                    <hr>
                    <div class="pt-2 pb-2 row" >
                    <a class="col-xl-2 text-center mt-1 link"  @click="$router.push({name:'watchStream',params:{id:data.id}})"  :title="data.creator.username" style="width: 160px; height: 160px;">
                        <img alt=""  class="mb-2" width="120" height="120" :src="data.creator.avatar">
                    </a>
                    <div class="col-xl-10 members-categories-details my-auto">
                        <div class="row mx-3">
                            <span class="ml-auto font-size-12 text-white py-1 px-3" style="border: 1px solid #f02f2f;border-radius: 4px;background-color: #f02f2f" v-if="data.is_online == 1">{{$t('profil_user.settings.live')}}</span>
                        </div>
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="mb-2 link" @click="$router.push({name:'ProfilUser',params:{id:data.creator.user_id}})">{{data.creator.username}}</h3>
                                <h6 class=" text-muted">{{data.title}}</h6>
                            </div>
                            <div class="col-auto mr-4">
                                <span  class="small-title">{{data.viewer_count}} viewer</span>
                            </div>
                        </div>
                        <!-- <div class="mt-2 mb-4">
                            {{data.challenge.name}} / {{data.challenge.category.name}}
                        </div> -->
                        <div class="members-categories-about-member">
                            {{data.bio}}
                        </div>
                        <div class="row mt-2" >
                                <button type="button" class="btn btn-primary btn-sm btn-rounded ml-auto mr-4"
                                    @click="$router.push({name:'watchStream',params:{id:data.id}})">
                                    <i class="bx bx-slideshow mr-2"></i>{{$t('streamings.watch')}}</button>
                        </div>
                    </div>

                </div>

                  </div>

                </div>
                <div class="card-body text-center" v-if="load && streamings.length == 0">
                    <h6 class="text-muted">{{$t('validation.no_streamer')}} <a href="#"  v-b-modal.modal-stream>{{$t('validation.b_str')}}</a></h6>
                    <new-stream/>
                </div>
                <div class="card-body" v-if="!load">
                      <div v-for="n in 4" :key="n">
                          <hr>

                          <b-row>
                              <b-col cols="3" class="mt-3">
                                  <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
                              </b-col>
                              <b-col cols="9" class="mt-3">
                                  <b-skeleton width="20%" class="mb-2"></b-skeleton>
                                  <b-skeleton  class="mb-2"></b-skeleton>
                                  <b-skeleton class="mb-2"></b-skeleton>
                                  <b-skeleton class="mb-2"></b-skeleton>
                                  <b-skeleton width="75%" class="mb-2 ml-2"></b-skeleton>
                                  <b-skeleton width="10%" class=" ml-auto" type="button"></b-skeleton>
                              </b-col>
                          </b-row>
                      </div>



                </div>
            </div>

            </div>



    </div>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import NewStream from "../../components/popups/newStream";

    export default {
        name: "streamers",
        components: {NewStream},
        created() {
            this.loadStreamings(1)
        },
        data(){
            return{
                streamings:[],
                load:false
            }
        },
        methods: {
            async loadStreamings(page) {
                let streamings = await helper.loadData('streamings', page);
                this.streamings = streamings.data;
                this.load = true
            },
        }
    }
</script>

<style scoped>

</style>